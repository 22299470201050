import './App.css';
import {useEffect, useState, CSSProperties} from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import {redirect} from "react-router-dom";


function App() {
  const [url, setUrl] = useState('')
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#ffffff");
  const appStore = 'https://popsical.onelink.me/hlk7/j1s8lnz4'
  const plan = 'https://web.popsical.com'
  const payment = 'https://web.popsical.com/payment/card'
  const account = 'https://web.popsical.com/account'
  const redeem = 'https://web.popsical.com/redeem'

  useEffect(() => {
    if(window.location.pathname.includes('plans')) {
      window.location.href = plan;
    }else if(window.location.pathname.includes('payment')){
      window.location.href = payment;
    } else if(window.location.pathname.includes('redeem')){
      window.location.href = redeem;
    }else if(window.location.pathname.includes('account')){
      var url = window.location.href;
      url = url.replace('ap.popsical.com', 'web.popsical.com')
      window.location.href = url;
    }
    else {
      window.location.href = appStore;
    }
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <ScaleLoader
          color={color}
          loading={loading}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </header>
    </div>
  );
}

export default App;
